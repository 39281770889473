import { createAction, props } from '@ngrx/store';
import { FRAMProfile, FRAMUser, FRAMUpdateProfile } from '../models';

export const profileValid = createAction(
    '[Profile] Valid',
    props<{ profile: FRAMProfile }>());
export const profileInvalid = createAction(
    '[Profile] Invalid',
    props<{ payload: any }>());
export const profileUpdate = createAction(
    '[Profile] Update',
    props<{ updatedProfile: FRAMUpdateProfile }>()
);
