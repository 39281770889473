import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { NgForm, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { WINDOW } from 'ngx-window-token';
import { FRAMAuthUI } from '../../core';
import { LoginAttempt, Unauthorized } from '../../models/authentication';
import { ICallback, ICallbackHandlers } from '../../models/field-config.interface';
import { FRAMAlertService } from '../../services';
import { OpenAMEventService } from '../../services/event.service';
import { OpenAMHTTPService } from '../../services/http.service';
import { JSZipService } from '../../services/zip.service';

@Component({
  exportAs: 'dynamicForm',
  selector: 'dynamic-form',
  styleUrls: ['dynamic-form.component.scss'],
  template: `
    <mat-progress-bar
      class="example-margin"
      [ngClass]="{'is-done': !active||progressActive}"
      color="primary"
      mode="indeterminate"
    >
    </mat-progress-bar>
    <form
      #dynamicForm
      [formGroup]="form"
      (submit)="handleSubmit($event)">
      <mat-card appearance="outlined">
        <mat-card-header class="page-header" [ngClass]="{'is-done': active}">
          <mat-card-title >{{ config.header }}</mat-card-title>
        </mat-card-header>
        <mat-card-content [ngClass]="{'is-done': active}">
          <ng-container
            *ngFor="let field of config.callbacks;"
            dynamicField
            [config]="field"
            [group]="form">
          </ng-container>
        </mat-card-content>
        <mat-card-actions [ngClass]="{'is-done': active}" align="end">
          <ng-container
            dynamicField
            [config]="loginField"
            [group]="form">
          </ng-container>
        </mat-card-actions>
      </mat-card>
    </form>
    <ng-container [ngTemplateOutlet]="currentTemplate">
    </ng-container>
  `
})
export class DynamicFormComponent implements OnInit {
  @ViewChild('dynamicForm', { static: true }) formRef: NgForm;
  @Input()
  config: ICallbackHandlers;
  loginField: ICallback = {
    type: 'button',
    output: [{ name: 'prompt', value: 'Log In' }],
    input: [{ name: 'submit', value: '' }]
  };

  // tslint:disable-next-line:no-output-native
  @Output()
  submit: EventEmitter<any> = new EventEmitter<any>();

  form: UntypedFormGroup;
  myForm: any;
  active = false;
  progressActive = true;
  dict = {};
  framEvent: Error | Unauthorized;

  get controls(): any { return this.config.callbacks.filter(({ type }) => type !== 'button'); }
  get changes(): any { return this.form.valueChanges; }
  get valid(): any { return this.form.valid; }
  get value(): any { return this.form.value; }
  get currentTemplate(): any { return this.dict[this.config.stage]; }

  constructor(
    @Inject(WINDOW) private window: any,
    private framauthui: FRAMAuthUI,
    public dialog: MatDialog,
    protected fb: UntypedFormBuilder,
    protected openAMHTTPService: OpenAMHTTPService,
    protected openAMEventService: OpenAMEventService,
    private framAlertService: FRAMAlertService,
    protected changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    // Initialise the form
    this.form = this.fb.group({});
    this.form = this.createGroup();
    this.changeDetectorRef.detectChanges();
    this.active = true;
    this.progressActive = false;
    if (this.framEvent) {
      console.log(this.framEvent)
      this.framAlertService.error(this.framEvent.message, true);
    }
  }

  getName(item: ICallback): string {
    let name = item.input && item.input[0].name;
    if (!name) {
      name = item.output && item.output[0].name;
    }
    return name;
  }

  get register(): boolean {
    return this.framauthui.settings.fram.urls[this.window.location.hostname].registration;
  }

  createGroup(): UntypedFormGroup {
    const group = this.fb.group({});
    // Check to see if we have an authId defined.
    if (this.config.authId) {
      this.controls.forEach(control => group.addControl(this.getName(control), this.createControl(control)));
      group.addControl(this.loginField.input[0].name, this.createControl(this.loginField));
    }
    return group;
  }

  createControl(config: ICallback): UntypedFormControl {
    const value = this.getName(config);
    return this.fb.control({ disabled: false, value }, [Validators.required]);
  }

  handleSubmit(event: Event): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const e = new LoginAttempt(this.config);
    this.openAMEventService.cartData.emit(e);
  }

  setValue(name: string, value: any): void {
    this.form.controls[name].setValue(value, { emitEvent: true });
  }

  compressFile(file: File): any {
    const zipService = new JSZipService();
    return zipService.compress(file);
  }
}
