import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { FRAMRegisterResponse } from '@darkedges/framauthui';
import { FRAMService } from '../../services/fram.service';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import {
  registerAccountInvalid,
  registerAccountRegister,
  registerAccountValid,
  SessionState
} from '@darkedges/framauthui';
import { State } from '@ngrx/store';

@Injectable()
export class RegisterEffects {
  constructor(
    private actions$: Actions,
    private state: State<SessionState>,
    private framService: FRAMService
  ) {
  }

  updateProfile$ = createEffect(
    () => this.actions$.pipe(
      ofType(registerAccountRegister),
      exhaustMap(action => {
        const username = this.state.getValue().framauthuiFeature.session.sessionInfo.username;
        return this.framService.registerUser(action.registerRequest).pipe(
          map((registerResponse: FRAMRegisterResponse) => registerAccountValid({ registerResponse })),
          catchError(error => of(registerAccountInvalid({payload: error}))));
      }
      )
    )
  );
}
