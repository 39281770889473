import { createAction, props } from '@ngrx/store';
import { FRAMRegisterResponse, FRAMRegisterRequest } from '../models';

export const registerAccountValid = createAction(
    '[RegisterAccount] Valid',
    props<{ registerResponse: FRAMRegisterResponse }>());
export const registerAccountInvalid = createAction(
    '[RegisterAccount] Invalid',
    props<{ payload: any }>());
export const registerAccountRegister = createAction(
    '[RegisterAccount] Register',
    props<{ registerRequest: FRAMRegisterRequest }>()
);
