import {
    FRAMProfile,
    selectProfile,
    ProfileActionTypes,
    ProfileState,
    profileUpdate
} from '@darkedges/framauthui';
import { Actions, ofType } from '@ngrx/effects';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

@Component({
    templateUrl: 'profileDetails.component.html',
    styleUrls: ['profileDetails.component.css'],
})
export class ProfileDetailsComponent implements OnInit, OnDestroy {
    profile$: Observable<FRAMProfile>;
    registerForm: UntypedFormGroup;
    destroyed$ = new Subject<boolean>();

    constructor(private snackBar: MatSnackBar, updates$: Actions, private store: Store<ProfileState>, private formBuilder: UntypedFormBuilder) {
        this.profile$ = this.store.pipe(select(selectProfile));
        updates$.pipe(
            ofType(ProfileActionTypes.VALID),
            takeUntil(this.destroyed$)).subscribe((action) => {
                this.snackBar.open('Your Profile has been Updated', null, {
                    duration: 5000,
                });
            });

        updates$.pipe(
            ofType(ProfileActionTypes.INVALID),
            takeUntil(this.destroyed$)).subscribe((action: any) => {
                if (action.payload.error) {
                    this.snackBar.open('Error: ' + action.payload.error.message, null, {
                        duration: 5000,
                        panelClass: ['error-snackbar']
                    });
                }
            });
    }

    ngOnInit(): void {
        this.profile$.subscribe(data => {
            if (data) {
                let givenName = '';
                let sn = '';
                let mail = '';
                if (data.givenName) {
                    givenName = data.givenName[0];
                }
                if (data.sn) {
                    sn = data.sn[0];
                }
                if (data.givenName) {
                    mail = data.mail[0];
                }
                this.registerForm = this.formBuilder.group({
                    givenName: [givenName, Validators.required],
                    sn: [sn, Validators.required],
                    mail: [mail, [Validators.required, Validators.email]]
                });
            }
        });
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    public hasError = (controlName: string, errorName: string) => {
        return this.registerForm.controls[controlName].hasError(errorName);
    }

    public createOwner = (ownerFormValue) => {
        if (this.registerForm.valid) {
            this.store.dispatch(profileUpdate({ updatedProfile: ownerFormValue }));
        }
    }

}
