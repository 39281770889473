import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoginAttempt } from '../models';
import { OpenAMEventService } from './event.service';

@Injectable()
export class FRAMAlertService {
    private subject = new BehaviorSubject<any>("");
    private keepAfterNavigationChange = false;

    constructor(private router: Router,
        private openAMEventService: OpenAMEventService,) {
        // clear alert message on route change
        this.openAMEventService.cartData.subscribe(event => {
            if (event instanceof LoginAttempt) {
                if (this.keepAfterNavigationChange) {
                    // only keep for a single location change
                    this.keepAfterNavigationChange = false;
                } else {
                    // clear alert
                    this.subject.next("");
                }
            }
        })
    }

    info(message: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'success', text: message, icon: 'pending' });
    }

    success(message: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'success', text: message, icon: 'pending' });
    }

    error(message: string, keepAfterNavigationChange = false) {
        this.keepAfterNavigationChange = keepAfterNavigationChange;
        this.subject.next({ type: 'error', text: message, icon: 'warning' });
    }

    clear() {
        this.keepAfterNavigationChange = false;
        this.subject.next("")
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}