import { AuthLoginComponent } from './pages/auth/login/login.component';
import { GlobalErrorComponent } from './components/global-error.component';
import { LoginGuard, LogoutGuard, ProfileExistsGuard } from './guards';
import { NgModule } from '@angular/core';
import { PageNotFoundComponent } from './components/pagenotfound.component';
import { ProfileDetailsComponent, ProfileSecurityComponent, ProfileComponent, LogoutComponent } from './pages/profile';
import { RouterModule, Routes } from '@angular/router';
import { RegisterComponent } from './pages/register/register.component';
import { OIDCCodeComponent } from './components/oidcCode.component';
import { IFrameComponent } from './pages/profile/iframe/iframe.component';

const routes: Routes = [
  {
    path: 'login',
    component: AuthLoginComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'logout',
    component: LogoutGuard,
    canActivate: [LogoutGuard]
  }, {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [LoginGuard],
    children: [
      {
        path: 'iframe',
        component: IFrameComponent,
        canActivate: [ProfileExistsGuard],
      },
      {
        path: 'details',
        component: ProfileDetailsComponent,
        data: {
          label: 'Details'
        },
        canActivate: [ProfileExistsGuard],
      },
      {
        path: 'security',
        component: ProfileSecurityComponent,
        data: {
          label: 'Security'
        }
      },
      {
        path: 'logout',
        component: LogoutComponent,
        data: {
          label: 'Logout'
        }
      }
    ]
  },
  {
    path: 'oidc/code',
    component: OIDCCodeComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'error',
    component: GlobalErrorComponent
  },
  {
    path: '404',
    component: PageNotFoundComponent
  },
  {
    path: '**',
    redirectTo: '/profile/details'
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
