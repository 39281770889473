import { DefaultConfig, FRAMAuthUISettings } from './framauthui-config';
import { FRAMAUTHUI_TOKEN, FRAMAuthUIToken } from './framauthui-token';
import { Inject, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class FRAMAuthUI {
    settings: FRAMAuthUISettings;

    constructor(
        @Inject(FRAMAUTHUI_TOKEN) setup: FRAMAuthUIToken,
    ) {
        const defaultConfig = new DefaultConfig();
        this.settings = { ...defaultConfig, ...setup.settings };
        this.settings.fram = {
            ...defaultConfig.fram,
            ...setup.settings.fram,
        };
    }
}
