import { Component, OnInit, OnDestroy, ViewEncapsulation, ChangeDetectionStrategy } from '@angular/core';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, ofType } from '@ngrx/effects';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ProfileState, passwordChange, FRAMChangePassword, passwordValid, passwordInvalid } from '@darkedges/framauthui';
import { takeUntil } from 'rxjs/operators';
import { MustNotMatch, MustMatch } from '../../../validators/must-match.validator';

@Component({
    templateUrl: 'profileSecurity.component.html',
    styleUrls: ['profileSecurity.component.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileSecurityComponent implements OnInit, OnDestroy {
    destroyed$ = new Subject<boolean>();
    registerForm: UntypedFormGroup;
    showPasswordStrengthInfo = false;

    constructor(private snackBar: MatSnackBar, private store: Store<ProfileState>, updates$: Actions, private formBuilder: UntypedFormBuilder) {
        updates$.pipe(
            ofType(passwordValid),
            takeUntil(this.destroyed$)).subscribe((action) => {
                this.snackBar.open('Your Password has been Updated', null, {
                    duration: 5000,
                });
            });
        updates$.pipe(
            ofType(passwordInvalid),
            takeUntil(this.destroyed$)).subscribe((action: any) => {
                this.snackBar.open('Error: ' + action.payload.error.message, null, {
                    duration: 5000,
                    panelClass: ['error-snackbar']
                });
            });
    }

    ngOnInit(): void {
        this.registerForm = this.formBuilder.group({
            currentPassword: ['', Validators.required],
            newPassword: ['', Validators.required],
            repeatPassword: ['', [Validators.required]]
        }, {
            validator: [
                MustNotMatch('currentPassword', 'newPassword'),
                MustMatch('newPassword', 'repeatPassword')
            ]
        });
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    public hasError = (controlName: string, errorName: string) => {
        return this.registerForm.controls[controlName].hasError(errorName);
    }

    public createOwner = (ownerFormValue) => {
        if (this.registerForm.valid) {
            this.store.dispatch(passwordChange(
                {
                    changePasswordRequest:
                        {
                            currentpassword: ownerFormValue.currentPassword,
                            username: '',
                            userpassword: ownerFormValue.newPassword
                        } as FRAMChangePassword
                }));
        }
    }

    show(e: any): void {
        this.showPasswordStrengthInfo = true;
    }

    hide(e: any): void {
        this.showPasswordStrengthInfo = false;
    }

}
