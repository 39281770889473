import { HttpInterceptor, HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { OpenAMInterceptor } from '../../interceptors/openam.interceptor';
import { HttpInterceptorHandler } from './customhttpclient';

@Injectable()
export class AMAuthHttpClient extends HttpClient {
    constructor(
        @Inject(HttpBackend) handler: HttpBackend,
        @Inject(OpenAMInterceptor) amInterceptor: OpenAMInterceptor,
    ) {
        const interceptors: HttpInterceptor[] = [amInterceptor];
        const chain = interceptors.reduceRight(
            (next, interceptor) => new HttpInterceptorHandler(next, interceptor), handler);
        super(chain);
    }
}
