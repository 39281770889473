import { Action, createReducer, on } from '@ngrx/store';
import { FRAMProfile } from '../models/profile';
import { authLogout } from './auth.actions';
import { profileInvalid, profileUpdate, profileValid } from './profile.actions';

export enum ProfileActionTypes {
    VALID = '[Profile] Valid',
    INVALID = '[Profile] Invalid',
    UPDATE = '[Profile] Update'
}

export interface ProfileState {
    error: any;
    profile: FRAMProfile;
}

export const initialProfileState: ProfileState = {
    error: null,
    profile: null
};

export const profileReducer = createReducer(
    initialProfileState,
    on(profileValid, (state, { profile }) => ({ ...state, profile })),
    on(profileInvalid, () => initialProfileState),
    on(profileUpdate, (state, { updatedProfile }) => ({ ...state, updatedProfile })),
    on(authLogout, () => initialProfileState)
);

// tslint:disable-next-line:typedef
export function profilereducer(state: ProfileState | undefined, action: Action) {
    return profileReducer(state, action);
}

export const getProfile = (state: ProfileState) => state.profile;
