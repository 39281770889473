import { Action, createReducer, on } from '@ngrx/store';
import { FRAMSessionInfo } from '../models/profile';
import { authLogout } from './auth.actions';
import { sessionValid } from './session.actions';

export interface SessionState {
    sessionInfo: FRAMSessionInfo;
}

export const initialSessionState: SessionState = {
    sessionInfo: null
};

export const sessionReducer = createReducer(
    initialSessionState,
    on(sessionValid, (state, { sessionInfo }) => ({ ...state, sessionInfo })),
    on(authLogout, () => initialSessionState)
);

// tslint:disable-next-line:typedef
export function sessionreducer(state: SessionState | undefined, action: Action) {
    return sessionReducer(state, action);
}

export const getSessionInfo = (state: SessionState) => state.sessionInfo;
