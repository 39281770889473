import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FRAMAlertService, authAccessToken, authLogin, authLogout } from '@darkedges/framauthui';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { SessionStorageService } from 'angular-web-storage';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private session: SessionStorageService,
    private route: ActivatedRoute,
    private framAlertService: FRAMAlertService
  ) { }

  login$ = createEffect(
    () => this.actions$.pipe(
      ofType(authLogin),
      tap(action => {
        const goto = this.route.snapshot.queryParamMap.get('goto');
        const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
        if (goto) {
          console.log(goto);
          location.href = goto;
        } else if (returnUrl) {
          this.router.navigate([returnUrl]);
        } else {
          this.router.navigate(['']);
        }
      })
    ), { dispatch: false }
  );

  accessToken$ = createEffect(
    () => this.actions$.pipe(
      ofType(authAccessToken),
      tap(action => {
        this.router.navigate(['']);
      })
    ), { dispatch: false }
  );

  logout$ = createEffect(
    () => this.actions$.pipe(
      ofType(authLogout),
      tap(action => {
        this.framAlertService.clear();
        this.router.navigate(['/login']);
      })
    ), { dispatch: false }
  );
}
