const global: any = {};

export class PlatformTools {
  /**
* Gets global variable where global stuff can be stored.
*/

  static getGlobalVariable(): any {
    return global;
  }
}