
import { Component } from '@angular/core';

@Component({
    templateUrl: 'logout.component.html',
    styleUrls: ['logout.component.css'],
})
export class LogoutComponent {


}
