import { createAction, props } from '@ngrx/store';
import { Authenticated, FRAMUser } from '../models/authentication';

export const authLogin = createAction(
    '[Auth] Login',
    props<{ authenticated: Authenticated }>());
export const authLogout = createAction('[Auth] Logout');
export const authAccessToken = createAction(
    '[Auth] Access Token',
    props<{ user: FRAMUser }>()
);
