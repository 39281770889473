import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { FRAMCallbackHandler } from '../../metadata/annotations/framcallbackhandler.annotation';
import { ICallback } from '../../models/field-config.interface';
import { Field } from '../../models/field.interface';

@FRAMCallbackHandler({
  type: 'HiddenValueCallback'
})
@Component({
  selector: 'hiddenvalue-callback',
  styleUrls: ['hiddenvalue-callback.component.scss'],
  template: `
    <div
      class="dynamic-field form-hiddenvalue-callback"
      [formGroup]="group">
      <input programmaticInputFireEvent
        type="hidden"  
        id="{{id}}"
        [attr.placeholder]="config.output[0].value"
        [formControlName]="config.input[0].name"
       >
    </div>
  `
})
export class HiddenValueCallbackComponent implements Field, AfterViewInit, OnInit {
  config: ICallback;
  group: UntypedFormGroup;
  form: any;
  id;

  ngAfterViewInit() {
    this.id = this.config.input[0].value;
  }

  ngOnInit() {
    this.group.get(this.config.input[0].name).setValue('');
    this.group.get(this.config.input[0].name).valueChanges
      .subscribe(val => {
        this.config.input[0].value = val;
      });
  }
}
