import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NgForm, UntypedFormGroup } from '@angular/forms';
import { PublicKeyCredentialCreationOptionsJSON, PublicKeyCredentialRequestOptionsJSON } from '@simplewebauthn/typescript-types';

import { WebAuthn } from '@darkedges/capacitor-native-passkey';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FRAMCallbackHandler } from '../../metadata/annotations/framcallbackhandler.annotation';
import { ICallback } from '../../models/field-config.interface';
import { Field } from '../../models/field.interface';
import { FRAMAlertService } from '../../services';
import { OpenAMEventService } from '../../services/event.service';

@FRAMCallbackHandler({
  type: 'MetadataCallback',
  id: 'WebAuthn'
}
)
@Component({
  selector: 'webauthn-callback',
  styleUrls: ['webauthn-callback.component.scss'],
  templateUrl: 'webauthn-callback.component.html'
})
export class WebAuthnCallbackComponent implements Field, AfterViewInit, OnInit {
  config: ICallback;
  group: UntypedFormGroup;
  form: NgForm;
  id;

  constructor(
    private deviceService: DeviceDetectorService,
    private openAMEventService: OpenAMEventService,
    private framAlertService: FRAMAlertService) {
  }

  ngOnInit() {
    const d = this.config.output[0].value as any;
    if (d._action === 'webauthn_authentication') {
      const credentials = d._allowCredentials.map((credential => {
        return {
          id: this.bufferToBase64URLString(new Uint8Array(credential.id).buffer),
          type: credential.type
        }
      }))
      let publicKeyCredentialRequestOptionsJSON: PublicKeyCredentialRequestOptionsJSON;
      publicKeyCredentialRequestOptionsJSON = {
        challenge: d.challenge,
        allowCredentials: credentials,
        timeout: d.timeout,
        userVerification: d.userVerification,
        rpId: d._relyingPartyId
      }
      this.framAlertService.info("Waiting for input from browser interaction...", true);
      WebAuthn.startAuthentication(publicKeyCredentialRequestOptionsJSON).then(
        authenticationResponseJSON => {
          var clientData = atob(authenticationResponseJSON.response.clientDataJSON);
          var authenticatorData = new Int8Array(this.base64URLStringToBuffer(authenticationResponseJSON.response.authenticatorData)).toString();
          var signature = new Int8Array(this.base64URLStringToBuffer(authenticationResponseJSON.response.signature)).toString();
          var rawId = authenticationResponseJSON.id;
          var userHandle = authenticationResponseJSON.response.userHandle ? authenticationResponseJSON.response.userHandle : "";
          this.group.controls.IDToken2.setValue(clientData + "::" + authenticatorData + "::" + signature + "::" + rawId + "::" + userHandle, { emitEvent: true });
          this.form.onSubmit(undefined);
          this.framAlertService.success("Verifying...", false);
        },
        async error => {
          console.log(error)
          this.framAlertService.error("Authentication failed.", true);
          this.group.controls.IDToken2.setValue("ERROR" + "::" + error, { emitEvent: true });
          this.form.onSubmit(undefined);
        }
      )
    }
    if (d._action === 'webauthn_registration') {
      let publicKeyCredentialRequestOptionsJSON: PublicKeyCredentialCreationOptionsJSON;
      publicKeyCredentialRequestOptionsJSON = {
        challenge: d.challenge,
        rp: {
          name: d.relyingPartyName,
          id: d._relyingPartyId
        },
        user: {
          id: d.userId,
          name: d.userName,
          displayName: d.displayName
        },
        pubKeyCredParams: d._pubKeyCredParams,
        timeout: d.timeout,
        attestation: d.attestationPreference,
        excludeCredentials: d._excludeCredentials,
        authenticatorSelection: d._authenticatorSelection
      };
      if (this.deviceService.isMobile()) {
        publicKeyCredentialRequestOptionsJSON.authenticatorSelection = {
          authenticatorAttachment: "platform",
          requireResidentKey: true,
          residentKey: "required",
          userVerification: "required"
        };
        publicKeyCredentialRequestOptionsJSON.extensions = {
          credProps: true
        };
      }
      WebAuthn.startRegistration(publicKeyCredentialRequestOptionsJSON).then(
        newCredentialInfo => {
          var rawId = newCredentialInfo.id;
          var clientData = atob(newCredentialInfo.response.clientDataJSON);
          var keyData = new Int8Array(this.base64URLStringToBuffer(newCredentialInfo.response.attestationObject)).toString();
          this.group.controls.IDToken2.setValue(clientData + "::" + keyData + "::" + rawId, { emitEvent: true });
          this.framAlertService.success("Registering...", false);
          this.form.onSubmit(undefined);
        },
        error => {
          this.framAlertService.error("Cancelled", true);
          this.group.controls.IDToken2.setValue("ERROR" + "::" + error, { emitEvent: true });
          this.form.onSubmit(undefined);
        }
      )
    }
  }

  ngAfterViewInit() {
    this.openAMEventService.uiData.emit({ loginButton: { isButtonVisible: false } })
  }

  base64URLStringToBuffer(base64URLString: string): ArrayBuffer {
    // Convert from Base64URL to Base64
    const base64 = base64URLString.replace(/-/g, '+').replace(/_/g, '/');
    /**
     * Pad with '=' until it's a multiple of four
     * (4 - (85 % 4 = 1) = 3) % 4 = 3 padding
     * (4 - (86 % 4 = 2) = 2) % 4 = 2 padding
     * (4 - (87 % 4 = 3) = 1) % 4 = 1 padding
     * (4 - (88 % 4 = 0) = 4) % 4 = 0 padding
     */
    const padLength = (4 - (base64.length % 4)) % 4;
    const padded = base64.padEnd(base64.length + padLength, '=');

    // Convert to a binary string
    const binary = atob(padded);

    // Convert binary string to buffer
    const buffer = new ArrayBuffer(binary.length);
    const bytes = new Uint8Array(buffer);

    for (let i = 0; i < binary.length; i++) {
      bytes[i] = binary.charCodeAt(i);
    }

    return buffer;
  }

  bufferToBase64URLString(buffer: ArrayBuffer): string {
    const bytes = new Uint8Array(buffer);
    let str = '';

    for (const charCode of bytes) {
      str += String.fromCharCode(charCode);
    }

    const base64String = btoa(str);

    return base64String.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
  }
}

