import { FRAMCallbackHandlerMetadataArgs } from "./metadata/metadata-args/framcallbackhandlermetadataargs"
import { PlatformTools } from "./metadata/platform/platformtools"

export class MetadataArgsStorage {
    // -------------------------------------------------------------------------
    // Properties
    // -------------------------------------------------------------------------
    readonly callbackhandlers: FRAMCallbackHandlerMetadataArgs[] = []
}
/**
 * Gets metadata args storage.
 */
export function getMetadataArgsStorage(): MetadataArgsStorage {
    // we should store metadata storage in a global variable otherwise it brings too much problems
    // one of the problem is that if any entity (or any other) will be imported before consumer will call
    // useContainer method with his own container implementation, that entity will be registered in the
    // old old container (default one post probably) and consumer will his entity.
    // calling useContainer before he imports any entity (or any other) is not always convenient.
    // another reason is that when we run migrations typeorm is being called from a global package
    // and it may load entities which register decorators in typeorm of local package
    // this leads to impossibility of usage of entities in migrations and cli related operations
    const globalScope = PlatformTools.getGlobalVariable()
    if (!globalScope.typeormMetadataArgsStorage)
        globalScope.typeormMetadataArgsStorage = new MetadataArgsStorage()

    return globalScope.typeormMetadataArgsStorage
}