
interface FRAMDetails {
    baseURL: string;
    realm?: string;
    service?: string;
    registration?: boolean;
}

export interface FRAMSettings {
    urls: Record<string, FRAMDetails>;
}

export interface OAuth2Settings {
    redirectURI: string;
    issuer: string;
    clientId: string;
    scopes: string;
    responseTypes: string;
}

export interface FRAMAuthUISettings {
    fram: Partial<FRAMSettings>;
    oauth2: Partial<OAuth2Settings>;
    developerMode: boolean;
}

export class DefaultConfig implements FRAMAuthUISettings {
    fram = {
        urls: {}
    };
    oauth2 = {
        redirectURI: '',
        issuer: '',
        clientId: '',
        scopes: '',
        responseTypes: ''
    };
    developerMode = false;
}
