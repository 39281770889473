import { Actions, ofType } from '@ngrx/effects';
import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
    ProfileState,
    registerAccountInvalid,
    registerAccountRegister,
    registerAccountValid
} from '@darkedges/framauthui';
import { Router } from '@angular/router';
import { SessionStorageService } from 'angular-web-storage';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    templateUrl: 'register.html',
    styleUrls: ['register.css'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegisterComponent implements OnInit, OnDestroy {
    destroyed$ = new Subject<boolean>();
    registerForm: UntypedFormGroup;
    showPasswordStrengthInfo = false;

    constructor(
        private router: Router, public session: SessionStorageService, private snackBar: MatSnackBar,
        private store: Store<ProfileState>, updates$: Actions, private formBuilder: UntypedFormBuilder) {
        updates$.pipe(
            ofType(registerAccountValid),
            takeUntil(this.destroyed$)).subscribe(action => {
                const snackBarRef = this.snackBar.open('Your account has been created', null, {
                    duration: 5000,
                });
                snackBarRef.afterDismissed().subscribe(() => {
                    this.router.navigate(['/login']);
                });
            });
        updates$.pipe(
            ofType(registerAccountInvalid),
            takeUntil(this.destroyed$)).subscribe(action => {
                this.snackBar.open('Error: ' + action.payload.error.message, null, {
                    duration: 5000,
                    panelClass: ['error-snackbar']
                });
            });
    }

    ngOnInit(): void {
        this.registerForm = this.formBuilder.group({
            username: ['', [Validators.required, Validators.pattern('^[a-z0-9_-]{6,15}$')]],
            givenName: ['', Validators.required],
            sn: ['', Validators.required],
            mail: ['', [Validators.required, Validators.email]],
            userPassword: ['', Validators.required],
            agree: [false, Validators.requiredTrue]
        });
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    public hasError = (controlName: string, errorName: string) => {
        return this.registerForm.controls[controlName].hasError(errorName);
    }

    public createOwner = (ownerFormValue) => {
        if (this.registerForm.valid) {
            this.store.dispatch(registerAccountRegister({
                registerRequest: {
                    input: {
                        user: {
                            username: ownerFormValue.username,
                            givenName: ownerFormValue.givenName,
                            sn: ownerFormValue.sn,
                            mail: ownerFormValue.mail,
                            userPassword: ownerFormValue.userPassword,
                            inetUserStatus: 'Active'
                        }
                    }
                }
            }));
        }
    }
    show(e: any): void {
        this.showPasswordStrengthInfo = true;
    }
    hide(e: any): void {
        this.showPasswordStrengthInfo = false;
    }


}

