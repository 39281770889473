import { Type } from "@angular/core";
import { getMetadataArgsStorage } from "../../globals";
import { Field } from "../../models/field.interface";
import { FRAMCallbackHandlerMetadataArgs } from "../metadata-args/framcallbackhandlermetadataargs";

export function getFilteredProperties(type: string, id?: string): Type<Field> {
    let v: FRAMCallbackHandlerMetadataArgs[]
    if (id) {
        v = getMetadataArgsStorage().callbackhandlers
            .filter((callbackhandler) => {
                return (
                    type == callbackhandler.type &&
                    id == callbackhandler.id
                )
            });
    } else {
        v = getMetadataArgsStorage().callbackhandlers
            .filter((callbackhandler) => {
                return (
                    type == callbackhandler.type
                )
            });
    }
    if (v.length == 1)
        return v[0].target as Type<Field>
    else
        return null
}

export function getSupportedTypes() {
    return getMetadataArgsStorage().callbackhandlers
        .filter((callbackhandler) => {
            return (callbackhandler.type.endsWith("Callback"))
        })
        .map((callbackhandler) => {
            return callbackhandler.type
        })
}