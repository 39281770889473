import { HttpInterceptor, HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { AMOAuthInterceptor } from '../../interceptors/oauth.interceptor';
import { OAuthNoopResourceServerErrorHandler } from '../oauth/OAuthNoopResourceServerErrorHandler';
import { HttpInterceptorHandler } from './customhttpclient';

@Injectable()
export class AMOAuthHttpClient extends HttpClient {
  constructor(@Inject(HttpBackend) handler: HttpBackend) {
    const interceptors: HttpInterceptor[] = [new AMOAuthInterceptor(new OAuthNoopResourceServerErrorHandler())];
    const chain = interceptors.reduceRight(
      (next, interceptor) => new HttpInterceptorHandler(next, interceptor), handler);
    super(chain);
  }
}
