import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromAuth from './auth.reducer';
import * as fromProfile from './profile.reducer';
import * as fromSession from './session.reducer';

export interface FRAMAuthUIState {
    auth: fromAuth.AuthState;
    session: fromSession.SessionState;
    profile: fromProfile.ProfileState;
}

export const reducers: ActionReducerMap<FRAMAuthUIState> = {
    auth: fromAuth.authreducer,
    session: fromSession.sessionreducer,
    profile: fromProfile.profilereducer
};

export const getFRAMAuthUIState = createFeatureSelector<FRAMAuthUIState>('framauthuiFeature');

export const selectAuthState = createSelector(
    getFRAMAuthUIState,
    (state: FRAMAuthUIState) => state.auth
);

export const selectSessionState = createSelector(
    getFRAMAuthUIState,
    (state: FRAMAuthUIState) => state.session
);

export const selectProfileState = createSelector(
    getFRAMAuthUIState,
    (state: FRAMAuthUIState) => state.profile
);

export const selectAccessToken = createSelector(
    selectAuthState,
    fromAuth.getAccessToken
);

export const selectIsAuthenticated = createSelector(
    selectAuthState,
    fromAuth.getIsAuthenticated
);

export const selectUser = createSelector(
    selectAuthState,
    fromAuth.getUser
);

export const selectProfile = createSelector(
    selectProfileState,
    fromProfile.getProfile
);

export const selectSessionInfo = createSelector(
    selectSessionState,
    fromSession.getSessionInfo
);
