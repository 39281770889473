import { Component, OnInit } from '@angular/core';
import { FRAMProfile, selectProfile, ProfileState } from '@darkedges/framauthui';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';

@Component({
    template: '',
    styles: []
})
export class IFrameComponent implements OnInit {
    profile$: Observable<FRAMProfile>;

    constructor(private store: Store<ProfileState>) {
        this.profile$ = this.store.pipe(select(selectProfile));
    }

    ngOnInit(): void {
        this.profile$.subscribe(data => {
            if (data) {
                console.log('need to close the window');
                window.opener.postMessage('close', '*');
            } else {
                console.log('no data');
            }
        });
    }

}
