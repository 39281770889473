import { AfterViewInit, Component, ElementRef, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { DOCUMENT } from '@angular/common';
import { FRAMCallbackHandler } from '../../metadata/annotations/framcallbackhandler.annotation';
import { ICallback } from '../../models/field-config.interface';
import { Field } from '../../models/field.interface';
@FRAMCallbackHandler({
  type: 'TextOutputCallback'
})
@Component({
  selector: 'textoutput-callback',
  styleUrls: ['textoutput-callback.component.scss'],
  template: `
    <div *ngIf="config.output[1].value !== '4'">
    {{ config.output[0].value }}
    </div>
  `
})
export class TextOutputCallbackComponent implements Field, AfterViewInit {
  config: ICallback;
  group: UntypedFormGroup;
  form: any;

  constructor(private el: ElementRef, @Inject(DOCUMENT) private document: Document) {
  }

  ngAfterViewInit() {
    if (this.config.output[1].value === '4') {
      setTimeout(() => {
        const script = document.createElement('script');
        script.async = false;
        let textNode = this.config.output[0].value;
        // if (textNode.includes('loginButton_0')) {
        //   textNode = textNode.replaceAll('loginButton_0', 'loginButton');
        // }
        const scriptContent = this.document.createTextNode(textNode);
        script.appendChild(scriptContent);
        console.log(script);
        this.el.nativeElement.appendChild(script);
      }, 0);
    }
  }
}
