<div class="auth-content">
    <mat-card appearance="outlined">
        <mat-card-header class="page-header">
            <mat-card-title>
                <img src="https://www.darkedges.com/images/connectid_logo.jpg" />
            </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <nav mat-tab-nav-bar [tabPanel]="tabPanel">
                <a *ngFor="let link of navLinks" mat-tab-link #rla="routerLinkActive"
                    [active]="isViewInitialized && isLinkActive(rla)" [routerLink]="link.path" routerLinkActive>
                    {{link.label}}
                </a>
            </nav>
            <mat-tab-nav-panel #tabPanel>
                <router-outlet></router-outlet>
            </mat-tab-nav-panel>
        </mat-card-content>
    </mat-card>
</div>