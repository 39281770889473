import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatPasswordStrengthModule } from '@darkedges/password-strength';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { StoreModule } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { ConfirmationCallbackComponent } from './components/confirmation-callback/confirmation-callback.component';
import { FormButtonComponent } from './components/form-button/form-button.component';
import { FormDialogComponent } from './components/form-dialog/form-dialog.component';
import { FRAMAlertComponent } from './components/fram-alert/fram-alert.component';
import { HiddenValueCallbackComponent } from './components/hiddenvalue-callback/hiddenvalue-callback.component';
import { AppMaterialModule } from './components/material.module';
import { MetaDataCallbackComponent } from './components/metadata-callback/metadata-callback.component';
import { NameCallbackComponent } from './components/name-callback/name-callback.component';
import { PasswordCallbackComponent } from './components/password-callback/password-callback.component';
import { RedirectCallbackComponent } from './components/redirect-callback/redirect-callback.component';
import { TextOutputCallbackComponent } from './components/textoutput-callback/textoutput-callback.component';
import { WebAuthnCallbackComponent } from './components/webauthn-callback/webauthn-callback.component';
import { DynamicFormComponent } from './containers/dynamic-form/dynamic-form.component';
import { ParentDynamicFormComponent } from './containers/parent-dynamic-form/parent-dynamic-form.component';
import { FRAMAuthUISettings } from './core/framauthui-config';
import { FRAMAuthUI } from './core/framauthui-core';
import { FRAMAUTHUI_TOKEN } from './core/framauthui-token';
import { DynamicFieldDirective } from './directives/dynamic-field/dynamic-field.directive';
import { FormHostDirective } from './directives/form-host/form-host.directive';
import { ProgrammaticInputFireEventDirective } from './directives/programmatic-input-fire-event/programmatic-input-fire-event.directive';
import { OpenAMInterceptor } from './interceptors/openam.interceptor';
import * as fromFRAMAuthUI from './reducers';
import { AuthGuardService } from './services/auth/auth-guard.service';
import { AuthService } from './services/auth/auth.service';
import { RoleGuardService } from './services/auth/role-guard.service';
import { OpenAMEventService } from './services/event.service';
import { FRAMAlertService } from './services/fram-alert.service';
import { OpenAMHTTPService } from './services/http.service';
import { AMOAuthHttpClient } from './services/httpclient/oauth.httpclient';
import { AMAuthHttpClient } from './services/httpclient/openam.httpclient';
import { OAuthService } from './services/oauth/oauth.service';
import { UrlHelperService } from './services/oauth/urlHelper.service';
import { JSZipService } from './services/zip.service';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AppMaterialModule,
    HttpClientModule,
    FlexLayoutModule,
    MatPasswordStrengthModule.forRoot(),
    StoreModule.forFeature('framauthuiFeature', fromFRAMAuthUI.reducers)
  ],
  declarations: [
    DynamicFieldDirective,
    ProgrammaticInputFireEventDirective,
    FormHostDirective,
    DynamicFormComponent,
    ParentDynamicFormComponent,
    NameCallbackComponent,
    PasswordCallbackComponent,
    RedirectCallbackComponent,
    HiddenValueCallbackComponent,
    TextOutputCallbackComponent,
    ConfirmationCallbackComponent,
    FRAMAlertComponent,
    WebAuthnCallbackComponent,
    MetaDataCallbackComponent,
    FormButtonComponent,
    FormDialogComponent
  ],
  exports: [
    DynamicFormComponent,
    ParentDynamicFormComponent,
    ReactiveFormsModule,
    HttpClientModule,
    DynamicFieldDirective,
    FRAMAlertComponent
  ],
  providers: [
    JSZipService,
    OpenAMEventService,
    OpenAMHTTPService,
    OAuthService,
    CookieService,
    AuthService,
    AMAuthHttpClient,
    OpenAMInterceptor,
    FRAMAlertService
  ]
})
export class FRAMAuthUIModule {
  constructor(@Optional() @SkipSelf() parentModule?: FRAMAuthUIModule) {
    if (parentModule) {
      throw new Error(
        'FRAMAuthUIModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(
    settings: Partial<FRAMAuthUISettings> = {},
  ): ModuleWithProviders<FRAMAuthUIModule> {
    return {
      ngModule: FRAMAuthUIModule,
      providers: [
        UrlHelperService,
        OAuthService,
        AuthService,
        AuthGuardService,
        RoleGuardService,
        CookieService,
        FRAMAlertService,
        AMOAuthHttpClient,
        {
          provide: FRAMAUTHUI_TOKEN,
          useValue: {
            settings
          }
        },
        FRAMAuthUI
      ]
    };
  }
}
