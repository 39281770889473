import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { FRAMCallbackHandler } from '../../metadata/annotations/framcallbackhandler.annotation';
import { ICallback } from '../../models/field-config.interface';
import { Field } from '../../models/field.interface';

@FRAMCallbackHandler({
  type: 'NameCallback'
})
@Component({
  selector: 'name-callback',
  styleUrls: ['name-callback.component.scss'],
  template: `
    <mat-form-field class="form-name-callback" [formGroup]="group">
    <mat-label>{{config.output[0].value}}</mat-label>
      <input matInput
        autocomplete="off"
        [placeholder]="config.output[0].value"
        [formControlName]="config.input[0].name"
        id="{{ id }}"
      >
    </mat-form-field>
  `
})
export class NameCallbackComponent implements Field, AfterViewInit, OnInit {
  config: ICallback;
  group: UntypedFormGroup;
  form: any;
  id: any = '';

  ngAfterViewInit() {
    setTimeout(() => {
      this.id = this.config.input[0].name;
    }, 0);
  }

  ngOnInit() {
    this.group.get(this.config.input[0].name).setValue('');
    this.group.get(this.config.input[0].name).valueChanges
      .subscribe(val => {
        this.config.input[0].value = val;
      });
  }

}
