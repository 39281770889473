import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { FRAMCallbackHandler } from '../../metadata/annotations/framcallbackhandler.annotation';
import { ICallback } from '../../models/field-config.interface';
import { Field } from '../../models/field.interface';
import { OpenAMEventService } from '../../services/event.service';

@FRAMCallbackHandler("button")
@Component({
  selector: 'form-button',
  styleUrls: ['form-button.component.scss'],
  template: `
    <section *ngIf="isButtonVisible"
      class="form-button"
      [formGroup]="group">
      <button
        id="loginButton"
        mat-raised-button
        color="primary"
        [disabled]="!group.valid"
        type="submit">
        {{config.output[0].value}}
      </button>
    </section >
  `
})
export class FormButtonComponent implements Field {
  config: ICallback;
  group: UntypedFormGroup;
  form: any;
  isButtonVisible: boolean = true

  constructor(
    private openAMEventService: OpenAMEventService
  ) {
    this.openAMEventService.uiData.subscribe((event) => {
      this.isButtonVisible = event.loginButton.isButtonVisible;
    })
  }
}
