import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FRAMAuthUIModule } from '@darkedges/framauthui';
import { MatPasswordStrengthModule } from '@darkedges/password-strength';
import { environment } from '../environments/environment';
import { AuthTreeFormComponent, DataStoreFormComponent } from './amextensions';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GlobalErrorComponent } from './components/global-error.component';
import { OIDCCodeComponent } from './components/oidcCode.component';
import { PageNotFoundComponent } from './components/pagenotfound.component';
import { CoreModule } from './core';
import { LoginGuard, LogoutGuard, ProfileExistsGuard } from './guards';
import { AuthLoginComponent } from './pages/auth/login/login.component';
import { LogoutComponent, ProfileComponent, ProfileDetailsComponent, ProfileSecurityComponent } from './pages/profile';
import { IFrameComponent } from './pages/profile/iframe/iframe.component';
import { RegisterComponent } from './pages/register/register.component';
import { FRAMService } from './services';
import { SharedModule } from './shared';

@NgModule({
    declarations: [
        AppComponent,
        PageNotFoundComponent,
        GlobalErrorComponent,
        AuthLoginComponent,
        DataStoreFormComponent,
        AuthTreeFormComponent,
        ProfileComponent,
        IFrameComponent,
        ProfileDetailsComponent,
        ProfileSecurityComponent,
        LogoutComponent,
        OIDCCodeComponent,
        RegisterComponent
    ],
    imports: [
        CoreModule,
        SharedModule,
        // FRAM Auth UI
        FRAMAuthUIModule.forRoot({
            fram: {
                urls: environment.FRAMURLS
            }
        }),
        // angular
        BrowserAnimationsModule,
        BrowserModule,
        AppRoutingModule,
        MatPasswordStrengthModule
    ],
    providers: [
        LoginGuard,
        LogoutGuard,
        ProfileExistsGuard,
        FRAMService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

