import { Action, createReducer, on } from '@ngrx/store';
import { FRAMUser } from '../models/authentication';
import { authAccessToken, authLogin, authLogout } from './auth.actions';

export enum AuthTokenTypes {
  AMTOKEN = '[Token] FRAM',
  ACCESS_TOKEN = '[Token] Access Token',
  OIDC = '[Token] OIDC'
}

export interface AuthState {
  isAuthenticated: boolean;
  accessToken: boolean;
  user: FRAMUser;
}

export const initialAuthState: AuthState = {
  isAuthenticated: false,
  accessToken: false,
  user: null
};

export const authReducer = createReducer(
  initialAuthState,
  on(authLogin, state => ({ ...state, isAuthenticated: true, accessToken: false })),
  on(authAccessToken, (state, { user }) => ({ ...state, isAuthenticated: true, accessToken: true, user })),
  on(authLogout, () => initialAuthState)
);

// tslint:disable-next-line:typedef
export function authreducer(state: AuthState | undefined, action: Action) {
  return authReducer(state, action);
}

export const getAccessToken = (state: AuthState) => state.accessToken;
export const getIsAuthenticated = (state: AuthState) => state.isAuthenticated;
export const getUser = (state: AuthState) => state.user;
