import { getMetadataArgsStorage } from "../../globals";
import { FRAMCallbackHandlerOptions } from "../options/framcallbackhandler.option";
import { ObjectUtils } from "../utils/objectutils.util";

export function FRAMCallbackHandler(
    nameOrOptions?: string | FRAMCallbackHandlerOptions,
    maybeOptions?: FRAMCallbackHandlerOptions,
): ClassDecorator {
    const options =
        (ObjectUtils.isObject(nameOrOptions)
            ? (nameOrOptions as FRAMCallbackHandlerOptions)
            : maybeOptions) || {}
    const name =
        typeof nameOrOptions === "string" ? nameOrOptions : options.type;
    return function (target) {
        getMetadataArgsStorage().callbackhandlers.push({
            target: target,
            type: name,
            id: options.id ? options.id : undefined,
        });
    }
}
