import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { FRAMCallbackHandler } from '../../metadata/annotations/framcallbackhandler.annotation';
import { ICallback } from '../../models/field-config.interface';
import { Field } from '../../models/field.interface';
@FRAMCallbackHandler({
  type: 'MetadataCallback'
}
)
@Component({
  selector: 'metadata-callback',
  styleUrls: ['metadata-callback.component.scss'],
  template: `
    <div>
     <h1>MetaCallbackComponent</h1>
    </div>
  `
})
export class MetaDataCallbackComponent implements Field, AfterViewInit, OnInit {
  config: ICallback;
  group: UntypedFormGroup;
  form: any;
  id;

  ngAfterViewInit() {
    this.id = this.config.input[0].value;
  }

  ngOnInit() {
    this.group.get(this.config.input[0].name).setValue('');
    this.group.get(this.config.input[0].name).valueChanges
      .subscribe(val => {
        this.config.input[0].value = val;
      });
  }
}
