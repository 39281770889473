import { Component } from '@angular/core';
import { DynamicFormComponent } from '@darkedges/framauthui';

@Component({
  exportAs: 'datastoreForm',
  selector: 'datastore-form',
  styleUrls: ['datastore.component.scss'],
  templateUrl: 'datastore.component.html'
})
export class DataStoreFormComponent extends DynamicFormComponent {
}
