import { AfterViewInit, Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { FRAMCallbackHandler } from '../../metadata/annotations/framcallbackhandler.annotation';
import { ICallback } from '../../models/field-config.interface';
import { Field } from '../../models/field.interface';

@FRAMCallbackHandler("ConfirmationCallback")
@Component({
  selector: 'confirmation-callback',
  styleUrls: ['confirmation-callback.component.scss'],
  template: `
    <div
      class="dynamic-field form-confirmation-callback"
      [formGroup]="group">
      <label>confirmation</label>
      <ng-container *ngFor="let option of options; let i = index">
        <button
          mat-raised-button
          color="primary"
          type="submit"
          (click)="open($event, i)"
        >
          {{ option }}
        </button>
      </ng-container>
    </div>
  `
})
export class ConfirmationCallbackComponent implements Field, AfterViewInit {
  config: ICallback;
  group: UntypedFormGroup;
  form: any;
  id: any = '';
  prompt: string;
  messageType: number;
  options: any;
  optionType: number;
  defaultOption: number;

  ngAfterViewInit() {
    setTimeout(() => {
      this.id = this.config.input[0].value;
      this.config.output.forEach(entry => {
        switch (entry.name) {
          case 'prompt':
            this.prompt = entry.value;
            break;
          case 'messageType':
            this.prompt = entry.value;
            break;
          case 'options':
            this.options = entry.value;
            break;
          case 'optionType':
            this.optionType = Number(entry.value);
            break;
          case 'defaultOption':
            this.defaultOption = Number(entry.value);
            break;
        }
      });
    }, 0);
  }

  public open(event, item) {
    this.config.input[0].value = item;
  }
}
