import { Component } from '@angular/core';
import { DynamicFormComponent } from '@darkedges/framauthui';

@Component({
  exportAs: 'authtreeForm',
  selector: 'authtree-form',
  styleUrls: ['authtree.component.scss'],
  templateUrl: 'authtree.component.html'
})
export class AuthTreeFormComponent extends DynamicFormComponent {
}
