import {
    HttpClient,
    HttpBackend,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpEvent
} from '@angular/common/http';

import { Observable } from 'rxjs';

export class HttpInterceptorHandler implements HttpHandler {
    constructor(private next: HttpHandler, private interceptor: HttpInterceptor) { }

    handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
        return this.interceptor.intercept(req, this.next);
    }
}

// export class CustomHttpClient extends HttpClient {
//     constructor(handler: HttpBackend, interceptors: HttpInterceptor[]) {
//         const chain = interceptors.reduceRight(
//             (next, interceptor) => new HttpInterceptorHandler(next, interceptor), handler);
//         super(chain);
//     }
// }
