export const environment = {
  production: false,
  test: true,
  FRAMURLS: {
    'auth.daml.darkedges.com': {
      baseURL: 'https://fram.daml.darkedges.com/openam',
      realm: ''
    },
    'auth.darkedges.com': {
      baseURL: 'https://fram.darkedges.com/openam',
      realm: ''
    },
    'auth.internal.darkedges.com': {
      baseURL: 'https://am.darkedges.com.au/openam',
      service: 'passkey',
      realm: 'root',
      registration: false
    },
    'auth.internal.darkedges.com.au': {
      baseURL: 'https://am.internal.darkedges.com.au/openam',
      realm: ''
    },
    'auth.connectid.darkedges.com': {
      baseURL: 'https://fram.connectid.darkedges.com/openam',
      service: 'passkey',
      realm: 'connectid',
      registration: false
    },
    'auth.connectid.darkedges.com.au': {
      baseURL: 'https://am.darkedges.com.au/openam',
      service: 'passkey',
      realm: 'root',
      registration: false
    },
    'auth.bank.darkedges.com': {
      baseURL: 'https://fram.bank.darkedges.com/openam',
      service: 'passkey',
      realm: 'bank',
      registration: false
    },
    'auth.cdr.darkedges.com': {
      baseURL: 'https://fram.cdr.darkedges.com/openam',
      realm: ''
    },
    'auth.health.darkedges.com': {
      baseURL: 'https://fram.health.darkedges.com/openam',
      realm: ''
    }
  }
};
