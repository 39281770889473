import { Injectable } from '@angular/core';
import {
    authLogout,
    AuthService,
    FRAMChangePassword,
    FRAMProfile,
    FRAMRegisterRequest,
    FRAMRegisterResponse,
    FRAMSessionInfo,
    FRAMUpdateProfile,
    OpenAMHTTPService,
    sessionInvalid,
    sessionValid
} from '@darkedges/framauthui';
import { Store } from '@ngrx/store';
import { SessionStorageService } from 'angular-web-storage';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class FRAMService {
    constructor(
        private openAMHTTPService: OpenAMHTTPService,
        private store: Store<any>,
        private authService: AuthService,
        private session: SessionStorageService,
    ) { }

    isLoggedIn(): Observable<boolean> {
        return this.openAMHTTPService.isLoggedIn<FRAMSessionInfo>().pipe(
            map((sessionInfo: FRAMSessionInfo) => {
                if (sessionInfo) {
                    this.store.dispatch(sessionValid({ sessionInfo }));
                    this.session.clear()
                    return true;
                } else {
                    this.store.dispatch(sessionInvalid());
                    return false;
                }
            }),
            catchError((err, caught) => {
                this.store.dispatch(sessionInvalid());
                return of(false);
            }));
    }

    logout(): Observable<boolean> {
        return this.openAMHTTPService.logout().pipe(
            map((result: any) => {
                this.store.dispatch(authLogout());
                return false;
            }), catchError((err, caught) => {
                this.store.dispatch(authLogout());
                return of(false);
            }));
    }

    selectProfile(uid: string): Observable<FRAMProfile> {
        return this.openAMHTTPService.profile<FRAMProfile>(uid);
    }

    updateProfile(uid: string, updatedProfile: FRAMUpdateProfile): Observable<FRAMProfile> {
        return this.openAMHTTPService.updateProfile<FRAMProfile>(uid, updatedProfile);
    }

    changePassword(uid: string, changePassword: FRAMChangePassword): Observable<FRAMProfile> {
        return this.openAMHTTPService.changePassword<FRAMProfile>(uid, changePassword);
    }

    registerUser(registerRequest: FRAMRegisterRequest): Observable<FRAMRegisterResponse> {
        return this.openAMHTTPService.registerUser<FRAMRegisterResponse>(registerRequest);
    }
}
