export interface IFRAMSessionInfoProperties {
    AMCtxId: string;
}

export interface IFRAMSessionInfo {
    username: string;
    universalId: string;
    realm: string;
    latestAccessTime: Date;
    maxIdleExpirationTime: Date;
    maxSessionExpirationTime: Date;
    properties: IFRAMSessionInfoProperties;
}


export class FRAMSessionInfo {
    framSessionInfo: IFRAMSessionInfo;

    constructor(framSessionInfo: IFRAMSessionInfo) {
        this.framSessionInfo = framSessionInfo;
    }

    getUsername(): string {
        return this.framSessionInfo.username;
    }
}
