import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[programmaticInputFireEvent]'
})
export class ProgrammaticInputFireEventDirective implements OnInit {
  nativeElement: any;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    this.nativeElement = elementRef.nativeElement;
  }

  ngOnInit() {
    __ngRendererSetElementAttributeHelper(this.renderer, this.nativeElement, 'disabled', 'true');
    Object.defineProperty(this.nativeElement, 'value', {
      get: function () {
        return this.getAttribute('value');
      },
      set: function (v) {
        this.setAttribute('value', v);
        this.dispatchEvent(new CustomEvent('input'));
      },
      configurable: true,
    });
  }
}

type AnyDuringRendererMigration = any;

function __ngRendererSplitNamespaceHelper(name: AnyDuringRendererMigration) {
    if (name[0] === ":") {
        const match = name.match(/^:([^:]+):(.+)$/);
        return [match[1], match[2]];
    }
    return ["", name];
}

function __ngRendererSetElementAttributeHelper(renderer: AnyDuringRendererMigration, element: AnyDuringRendererMigration, namespaceAndName: AnyDuringRendererMigration, value?: AnyDuringRendererMigration) {
    const [namespace, name] = __ngRendererSplitNamespaceHelper(namespaceAndName);
    if (value != null) {
        renderer.setAttribute(element, name, value, namespace);
    }
    else {
        renderer.removeAttribute(element, name, namespace);
    }
}
