import { Component } from '@angular/core';

@Component({
    template: `
        <h2>Page Not Found!</h2>
        <a routerLink="/login">Sign In</a>
    `
})
export class PageNotFoundComponent {
}
