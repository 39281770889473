import {
  ComponentRef,
  Directive,
  Input,
  OnChanges,
  OnInit,
  ViewContainerRef
} from '@angular/core';
import { NgForm, UntypedFormGroup } from '@angular/forms';
import { getFilteredProperties, getSupportedTypes } from '../../metadata';
import { ICallback } from '../../models/field-config.interface';
import { Field } from '../../models/field.interface';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[dynamicField]'
})
export class DynamicFieldDirective implements Field, OnChanges, OnInit {
  @Input()
  config: ICallback;

  @Input()
  group: UntypedFormGroup;

  @Input()
  form: NgForm;

  component: ComponentRef<Field>;

  constructor(
    private container: ViewContainerRef
  ) { }

  ngOnChanges(): void {
    if (this.component) {
      this.component.instance.config = this.config;
      this.component.instance.group = this.group;
      this.component.instance.form = this.form;
    }
  }

  ngOnInit(): void {
    let id;
    if (this.config.type === "MetadataCallback") {
      id = (this.config.output[0].value as any)._type
    }
    const component = getFilteredProperties(this.config.type, id);
    if (!component) {
      const supportedTypes = getSupportedTypes();
      throw new Error(
        `Trying to use an unsupported type (${this.config.type}).
        Supported types: ${supportedTypes}`
      );
    }
    this.component = this.container.createComponent(component);
    this.component.instance.config = this.config;
    this.component.instance.group = this.group;
    this.component.instance.form = this.form;
  }
}
