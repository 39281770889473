import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { FRAMCallbackHandler } from '../../metadata/annotations/framcallbackhandler.annotation';
import { ICallback } from '../../models/field-config.interface';
import { Field } from '../../models/field.interface';
@FRAMCallbackHandler({
  type: 'RedirectCallback'
})
@Component({
  selector: 'redirect-callback',
  styleUrls: ['redirect-callback.component.scss'],
  template: `
  <div class="flex-container flex-center">
    <mat-spinner></mat-spinner>
  </div>
  `
})
export class RedirectCallbackComponent implements Field, AfterViewInit, OnInit {
  config: ICallback;
  group: UntypedFormGroup;
  form: any;

  ngAfterViewInit() {

    const url = this.config.output[0].value;
    const obj = this.getUrlParams(url);

    const mapForm = document.createElement('form');
    mapForm.target = '_top';
    mapForm.method = this.config.output[1].value;
    mapForm.action = url.substr(0, url.indexOf('?'));

    Object.keys(obj).forEach(function (param) {
      const mapInput = document.createElement('input');
      mapInput.type = 'hidden';
      mapInput.name = param;
      mapInput.setAttribute('value', obj[param]);
      mapForm.appendChild(mapInput);
    });
    document.body.appendChild(mapForm);
    mapForm.submit();
  }

  ngOnInit() {
  }

  getUrlParams(search) {
    const hashes = search.slice(search.indexOf('?') + 1).split('&');
    return hashes.reduce((params, hash) => {
      const [key, val] = hash.split('=');
      return Object.assign(params, { [key]: decodeURIComponent(val) });
    }, {});
  }

}
