import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { FRAMService } from '../services/fram.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class LoginGuard  {

    constructor(private framService: FRAMService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.framService.isLoggedIn().pipe(
            map(res => {
                if (res) {
                    if (state.url.startsWith('/login')) {
                        this.router.navigate(['/profile/details']);
                        return false;
                    }
                    return true;
                } else {
                    if (state.url.startsWith('/login')) {
                        return true;
                    } else {
                        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
                        return false;
                    }
                }
            })
        );
    }
}
