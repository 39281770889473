<div class="auth-content">
    <section fxLayout="row wrap" fxLayoutAlign="center center">
        <mat-card appearance="outlined" fxFlex="500px" fxFlex.xs="100%">
            <form [formGroup]="registerForm" autocomplete="off" novalidate (ngSubmit)="createOwner(registerForm.value)"
                fxLayout="column wrap" fxLayoutAlign="center center" fxLayoutGap="10px">
                <mat-card-title><img src="https://www.darkedges.com/images/connectid_logo.jpg" />
                    <br />Sign Up
                </mat-card-title>
                <mat-card-content>
                    <mat-form-field appearance="outline">
                        <mat-label>Username</mat-label>
                        <input matInput type="text" placeholder="Username" formControlName="username" id="username"
                            minlength="6" oninput="this.value = this.value.toLowerCase()" required>
                        <mat-error *ngIf="hasError('username', 'pattern')">Username must be lowercase</mat-error>
                        <mat-error *ngIf="hasError('username', 'required')">Username required</mat-error>
                        <mat-error *ngIf="hasError('username', 'minlength')">Username must be at least 6 characters long
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>First Name</mat-label>
                        <input matInput type="text" placeholder="First Name" formControlName="givenName" id="givenName"
                            required>
                        <mat-error *ngIf="hasError('givenName', 'required')">First Name is required</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Last Name</mat-label>
                        <input matInput type="text" placeholder="Last Name" formControlName="sn" id="sn" required>
                        <mat-error *ngIf="hasError('sn', 'required')">Last Name is required</mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput type="text" placeholder="Email" formControlName="mail" id="mail" required>
                        <mat-error *ngIf="hasError('mail', 'required')">Email is required</mat-error>
                        <mat-error *ngIf="hasError('mail', 'email')">Email must be a valid email address</mat-error>
                    </mat-form-field>


                    <mat-form-field appearance="outline" [color]="passwordComponentWithValidation.color">
                        <mat-label>Password</mat-label>
                        <input matInput #passwordWithValidation type="password" placeholder="Password"
                            formControlName="userPassword" id="userPassword" autocomplete="off" [type]="toggle.type"
                            (focus)="show($event)" (focusout)="hide($event)" required>
                        <mat-pass-toggle-visibility #toggle matSuffix></mat-pass-toggle-visibility>
                        <mat-hint align="end" aria-live="polite">
                            {{passwordWithValidation.value.length}} / {{passwordComponentWithValidation.max}}
                        </mat-hint>
                        <mat-error *ngIf="passwordComponentWithValidation.passwordFormControl.hasError('required')">
                            Password is required
                        </mat-error>
                        <mat-error *ngIf="passwordComponentWithValidation.passwordFormControl.hasError('pattern')">
                            Password is not valid
                        </mat-error>
                    </mat-form-field>
                    <mat-password-strength #passwordComponentWithValidation class="green"
                        [password]="passwordWithValidation.value">
                    </mat-password-strength>
                    <mat-password-strength-info *ngIf="showPasswordStrengthInfo"
                        [passwordComponent]="passwordComponentWithValidation">
                    </mat-password-strength-info>

                    <mat-checkbox formControlName="agree">
                        I agree to the terms and conditions
                    </mat-checkbox>
                </mat-card-content>
                <mat-card-actions align="center">
                    <button mat-raised-button color="primary" [disabled]="!registerForm.valid">Sign Up</button>
                </mat-card-actions>
            </form>
        </mat-card>
    </section>
</div>