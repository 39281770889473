import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, exhaustMap } from 'rxjs/operators';
import {
  FRAMProfile,
  profileInvalid,
  profileValid,
  SessionState,
  profileUpdate
} from '@darkedges/framauthui';
import { FRAMService } from '../../services/fram.service';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { State } from '@ngrx/store';

@Injectable()
export class ProfileEffects {
  constructor(
    private actions$: Actions,
    private state: State<SessionState>,
    private framService: FRAMService
  ) {
  }

  updateProfile$ = createEffect(
    () => this.actions$.pipe(
      ofType(profileUpdate),
      exhaustMap(action => {
        const username = this.state.getValue().framauthuiFeature.session.sessionInfo.username;
        return this.framService.updateProfile(username, action.updatedProfile).pipe(
          map((profile: FRAMProfile) => profileValid({ profile })),
          catchError(error => of(profileInvalid(error))));
      }
      )
    )
  );
}
