import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import {
    catchError,
    map,
    switchMap,
    take,
    tap
    } from 'rxjs/operators';
import {
    FRAMProfile,
    selectProfile,
    ProfileState,
    profileValid
    } from '@darkedges/framauthui';
import { FRAMService } from '../services';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { select, State, Store } from '@ngrx/store';

@Injectable()
export class ProfileExistsGuard  {

    constructor(private store: Store<ProfileState>, private state: State<ProfileState>, private framService: FRAMService) { }

    hasProfileInApi(): Observable<boolean> {
        const username = this.state.getValue().framauthuiFeature.session.sessionInfo.username;
        return this.framService.selectProfile(username).pipe(
            map((profile: FRAMProfile) => profileValid({ profile })),
            tap(action => this.store.dispatch(action)),
            map(profile => !!profile),
            catchError(() => {
                return of(false);
            })
        );
    }

    hasProfileInStore(): Observable<boolean> {
        return this.store.pipe(
            select(selectProfile),
            map(entities => {
                return !!entities;
            }
            ),
            take(1)
        );
    }

    hasProfile(): Observable<boolean> {
        return this.hasProfileInStore().pipe(
            switchMap(inStore => {
                if (inStore) {
                    return of(inStore);
                }
                return this.hasProfileInApi();
            })
        );
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.hasProfile();
    }
}
