import { Authenticated, Error, Unauthorized } from '../models/authentication';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OpenAMEventService } from '../services/event.service';
import { tap } from 'rxjs/operators';

// import {  AuthService } from '../services/auth/auth.service';

@Injectable()
export class OpenAMInterceptor implements HttpInterceptor {

  constructor(private openAMEventService: OpenAMEventService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            if (event.body.successUrl) {
              const e = new Authenticated(event.body);
              this.openAMEventService.cartData.emit(e);
              // this.authService.login(e);
            }
          }
        },
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              switch (err.status) {
                case 401:
                  const e = new Unauthorized(err.error);
                  this.openAMEventService.cartData.emit(e);
                  break;
                default:
                  const f = new Error('error');
                  this.openAMEventService.cartData.emit(f);
              }
            }
          }
        )
      );
  }
}
