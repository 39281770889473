<section fxLayout="row wrap" fxLayoutAlign="center center">
    <mat-card appearance="outlined" fxFlex="500px" fxFlex.xs="100%">
        <form [formGroup]="registerForm" autocomplete="off" novalidate (ngSubmit)="createOwner(registerForm.value)"
            fxLayout="column wrap" fxLayoutAlign="center center" fxLayoutGap="10px">
            <mat-card-content>
                To update your password enter your current password followed by your new password.
                <mat-form-field appearance="outline">
                    <mat-label>Current Password</mat-label>
                    <input type="text" name="email" value="..." autocomplete="username email" style="display: none;">
                    <input matInput #currentPassword [type]="toggle1.type" placeholder="Current Password"
                        formControlName="currentPassword" id="currentPassword" autocomplete="current-password"
                        type="password" required>
                    <mat-pass-toggle-visibility #toggle1 matSuffix></mat-pass-toggle-visibility>
                    <mat-error *ngIf="hasError('currentPassword', 'required')">Current Password is required</mat-error>
                    <mat-error *ngIf="hasError('currentPassword', 'minlength')">Passwords must be at least 8 characters
                        long</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" [color]="passwordComponentWithValidation.color">
                    <mat-label>New Password</mat-label>
                    <input matInput #passwordWithValidation placeholder="Password" formControlName="newPassword"
                        id="userPassword" autocomplete="new-password" type="password" [type]="toggle2.type"
                        (focus)="show($event)" (focusout)="hide($event)" required>
                    <mat-pass-toggle-visibility #toggle2 matSuffix></mat-pass-toggle-visibility>
                    <mat-hint align="end" aria-live="polite">
                        {{passwordWithValidation.value.length}} / {{passwordComponentWithValidation.max}}
                    </mat-hint>
                    <mat-error *ngIf="passwordComponentWithValidation.passwordFormControl.hasError('required')">
                        Password is required
                    </mat-error>
                    <mat-error *ngIf="passwordComponentWithValidation.passwordFormControl.hasError('pattern')">
                        Password is not valid
                    </mat-error>
                </mat-form-field>
                <mat-password-strength #passwordComponentWithValidation class="green"
                    [password]="passwordWithValidation.value">
                </mat-password-strength>
                <mat-password-strength-info *ngIf="showPasswordStrengthInfo"
                    [passwordComponent]="passwordComponentWithValidation">
                </mat-password-strength-info>
                <mat-form-field appearance="outline" class="mt-3">
                    <mat-label>Repeat Password</mat-label>
                    <input matInput #repeatPassword [type]="toggle3.type" placeholder="Repeat Password"
                        formControlName="repeatPassword" id="repeatPassword" autocomplete="new-password" type="password"
                        required>
                    <mat-pass-toggle-visibility #toggle3 matSuffix></mat-pass-toggle-visibility>
                    <mat-error *ngIf="hasError('repeatPassword', 'required')">Repeat Password is required</mat-error>
                    <mat-error *ngIf="hasError('repeatPassword', 'mustMatch')">Current and New Passwords must match
                    </mat-error>
                    <mat-error *ngIf="hasError('repeatPassword', 'minlength')">Passwords must be at least 8 characters
                        long</mat-error>
                </mat-form-field>
            </mat-card-content>
            <mat-card-actions class="center">
                <button mat-raised-button color="primary" [disabled]="!registerForm.valid">Update</button>
            </mat-card-actions>
        </form>
    </mat-card>
</section>