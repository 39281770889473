import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { FRAMCallbackHandler } from '../../metadata/annotations/framcallbackhandler.annotation';
import { Unauthorized } from '../../models/authentication';
import { ICallback } from '../../models/field-config.interface';
import { Field } from '../../models/field.interface';
import { OpenAMEventService } from '../../services/event.service';
@FRAMCallbackHandler({
  type: 'PasswordCallback'
})
@Component({
  exportAs: 'passwordCallback',
  selector: 'password-callback',
  styleUrls: ['password-callback.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <mat-form-field #passwordCallback class="form-password-callback" [formGroup]="group">
      <input matInput
        [type]="toggle.type"
        autocomplete="off"
        [placeholder]="config.output[0].value"
        [formControlName]="config.input[0].name"
        [type]="toggle.type"
      >
    <mat-pass-toggle-visibility #toggle matSuffix></mat-pass-toggle-visibility>
    </mat-form-field>
  `
})
export class PasswordCallbackComponent implements Field, OnInit {
  @ViewChild('passwordCallback', { static: true }) formRef: ElementRef;
  config: ICallback;
  group: UntypedFormGroup;
  form: any;

  constructor(private openAMEventService: OpenAMEventService) {
    this.openAMEventService.cartData.subscribe(
      (data: any) => {
        if (data instanceof Unauthorized) {
        }
      });
  }

  ngOnInit() {
    this.group.get(this.config.input[0].name).setValue('');
    this.group.get(this.config.input[0].name).valueChanges
      .subscribe(val => {
        this.config.input[0].value = val;
      });
  }
}
