import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthState, authReducer } from '@darkedges/framauthui';
import { EffectsModule } from '@ngrx/effects';
import {
    ActionReducer,
    ActionReducerMap,
    MetaReducer,
    StoreModule
} from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { storeFreeze } from 'ngrx-store-freeze';
import { localStorageSync } from 'ngrx-store-localstorage';
import { environment } from '../../environments/environment';
import {
    AuthEffects,
    PasswordEffects,
    ProfileEffects,
    RegisterEffects
} from './effects';
import { LocalStorageService } from './local-storage/local-storage.service';
import { debug } from './meta-reducers/debug.reducer';

// tslint:disable-next-line:no-empty-interface
export interface AppState {
    framauthuiFeature: AuthState;
}

const reducers: ActionReducerMap<AppState> = {
    framauthuiFeature: authReducer
};

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({
        keys: ['framauthuiFeature'],
        rehydrate: true
    })(reducer);
}
const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

if (!environment.production) {
    metaReducers.unshift(storeFreeze);
    if (!environment.test) {
        metaReducers.unshift(debug);
    }
}

@NgModule({
    imports: [
        // angular
        CommonModule,
        HttpClientModule,

        // ngrx
        StoreModule.forRoot(
            reducers,
            {
                metaReducers,
                runtimeChecks: {
                    strictStateImmutability: true,
                    strictActionImmutability: true
                }
            }
        ),
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !environment.production }),
        EffectsModule.forRoot([
            AuthEffects, ProfileEffects, PasswordEffects, RegisterEffects
        ]),
    ],
    declarations: [],
    providers: [LocalStorageService]
})
export class CoreModule {
    constructor(
        @Optional()
        @SkipSelf()
        parentModule: CoreModule
    ) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import only in AppModule');
        }
    }
}
