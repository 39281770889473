import {
    HttpInterceptor, HttpRequest, HttpHandler, HttpEvent
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { OAuthResourceServerErrorHandler } from '../services/oauth/OAuthNoopResourceServerErrorHandler';

export class AMOAuthInterceptor implements HttpInterceptor {
    private _storage: Storage;

    constructor(
        private errorHandler: OAuthResourceServerErrorHandler
    ) {
        this.setStorage(sessionStorage);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this._storage.getItem('access_token');
        const header = 'Bearer ' + token;

        const headers = req.headers.set('Authorization', header);

        req = req.clone({ headers });

        return next
            .handle(req)
            .pipe(catchError(err => this.errorHandler.handleError(err)));
    }


    public setStorage(storage: Storage): void {
        this._storage = storage;
    }
}
