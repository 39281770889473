import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { WINDOW } from 'ngx-window-token';
import { FRAMAuthUI } from '../core/framauthui-core';
import { ICallbackHandlers } from '../models/field-config.interface';
import { FRAMChangePassword, FRAMUpdateProfile } from '../models/profile';
import { FRAMRegisterRequest } from '../models/register';
import { AMAuthHttpClient } from './httpclient/openam.httpclient';

@Injectable()
export class OpenAMHTTPService {
  private http: AMAuthHttpClient;
  constructor(
    private injector: Injector,
    private framauthui: FRAMAuthUI,
    private httpClient: AMAuthHttpClient,
    @Inject(WINDOW) private window: any) {
  }

  getBaseURL(): string {
    return this.framauthui.settings.fram.urls[this.window.location.hostname].baseURL;
  }

  getRealm(): string {
    let realm = this.framauthui.settings.fram.urls[this.window.location.hostname].realm;
    realm = realm ? 'realms/' + realm + '/' : "";
    return realm;
  }

  isLoggedIn<T>(): Observable<T> {
    this.http = this.injector.get(AMAuthHttpClient);
    return this.http.post<T>(
      this.getBaseURL() + '/json/' + this.getRealm() + 'sessions?_action=getSessionInfo', null,
      {
        headers: this.getHeaders(),
        withCredentials: true
      }
    );
  }

  login<T>(parameters?: string): Observable<T> {
    this.http = this.injector.get(AMAuthHttpClient);
    let url = this.getBaseURL() + '/json/' + this.getRealm() + 'authenticate';
    if (parameters) {
      url = `${url}?${parameters}`;
    }
    return this.http.post<T>(
      url, null,
      {
        headers: this.getHeaders(),
        withCredentials: true
      }
    );
  }

  profile<T>(uid: string): Observable<T> {
    this.http = this.injector.get(AMAuthHttpClient);
    return this.http.get<T>(
      this.getBaseURL() + '/json/' + this.getRealm() + 'users/' + uid,
      {
        headers: this.getHeaders(),
        withCredentials: true
      }
    );
  }

  updateProfile<T>(uid: string, update: FRAMUpdateProfile): Observable<T> {
    this.http = this.injector.get(AMAuthHttpClient);
    return this.http.put<T>(
      this.getBaseURL() + '/json/' + this.getRealm() + 'users/' + uid, update,
      {
        headers: this.getHeaders2_0(),
        withCredentials: true
      }
    );
  }

  changePassword<T>(uid: string, passwordRequest: FRAMChangePassword): Observable<T> {
    this.http = this.injector.get(AMAuthHttpClient);
    return this.http.post<T>(
      this.getBaseURL() + '/json/' + this.getRealm() + 'users/' + uid + '?_action=changePassword',
      passwordRequest,
      {
        headers: this.getHeaders(),
        withCredentials: true
      }
    );
  }

  registerUser<T>(registerUser: FRAMRegisterRequest): Observable<T> {
    this.http = this.injector.get(AMAuthHttpClient);
    return this.http.post<T>(
      this.getBaseURL() + '/json/' + this.getRealm() +
      'selfservice/userRegistration?_action=submitRequirements',
      registerUser,
      {
        headers: this.getRegisterHeaders(),
        withCredentials: true
      }
    );
  }

  logout<T>(): Observable<T> {
    return this.httpClient.post<T>(
      this.getBaseURL() + '/json/sessions?_action=logout', null,
      {
        headers: this.getHeaders(),
        withCredentials: true
      }
    );
  }

  submit<T>(response: ICallbackHandlers, parameters?: string): Observable<T> {
    this.http = this.injector.get(AMAuthHttpClient);
    let url = this.getBaseURL() + '/json/' + this.getRealm() + 'authenticate';
    if (parameters.startsWith('?')) {
      parameters = parameters.substring(1);
    }
    if (parameters) {
      url = `${url}?${parameters}`;
    }

    return this.http.post<T>(
      url, response,
      {
        headers: this.getHeaders(),
        withCredentials: true
      }
    );
  }

  private getHeaders(): HttpHeaders {
    const headers = new HttpHeaders()
      .append('content-type', 'application/json')
      .append('accept-api-version', 'protocol=1.0,resource=2.1')
      .append('accept', 'application/json');
    return headers;
  }


  private getHeaders2_0(): HttpHeaders {
    const headers = new HttpHeaders()
      .append('content-type', 'application/json')
      .append('accept-api-version', 'protocol=1.0,resource=2.0')
      .append('accept', 'application/json')
      .append('x-requested-with', 'XMLHttpRequest');
    return headers;
  }

  private getRegisterHeaders(): HttpHeaders {
    const headers = new HttpHeaders()
      .append('content-type', 'application/json')
      .append('accept-api-version', 'protocol=1.0,resource=1.0')
      .append('accept', 'application/json');
    return headers;
  }

}
