import { Injectable } from '@angular/core';
import * as JSZip from 'jszip';

@Injectable()
export class JSZipService {
  zip: any;

  constructor() {
    this.zip = new JSZip();
  }

  compress(file: File): any {
    this.zip.file(file.name, file);
    return this.zip.generateAsync({ type: 'base64' });
  }
}
