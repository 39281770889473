<section fxLayout="row wrap" fxLayoutAlign="center center">
    <mat-card appearance="outlined" fxFlex="500px" fxFlex.xs="100%">
        <form autocomplete="off" novalidate fxLayout="column wrap" fxLayoutAlign="center center" fxLayoutGap="10px">
            <mat-card-content>
                Are you Sure?
            </mat-card-content>
            <mat-card-actions class="center">
                <a routerLink="/logout"><button mat-raised-button color="primary"
                        style="margin-right: 5px">Yes</button></a>
                <a routerLink="/profile/details"><button mat-raised-button color="warning"
                        style="margin-right: 5px">No</button></a>
            </mat-card-actions>
        </form>
    </mat-card>
</section>