import {
  Component, ComponentFactoryResolver, Inject, Input, OnDestroy, OnInit, ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { SessionStorageService } from 'angular-web-storage';
import { WINDOW } from 'ngx-window-token';
import { Subscription } from 'rxjs';
import { DynamicFormComponent } from '../../containers/dynamic-form/dynamic-form.component';
import { FRAMAuthUI } from '../../core/framauthui-core';
import { FormHostDirective } from '../../directives/form-host/form-host.directive';
import { Authenticated, Error, LoginAttempt, Unauthorized } from '../../models/authentication';
import { ICallbackHandlers } from '../../models/field-config.interface';
import { OpenAMEventService } from '../../services/event.service';
import { OpenAMHTTPService } from '../../services/http.service';

@Component({
  exportAs: 'parentDynamicForm',
  selector: 'parent-dynamic-form',
  styleUrls: ['parent-dynamic-form.component.scss'],
  template: `
    <mat-progress-bar
      class="example-margin"
      [ngClass]="{'is-done': !active||progressActive}"
      color="primary"
      mode="indeterminate"
    >
    </mat-progress-bar>
    <ng-template form-host></ng-template>
  `
})
export class ParentDynamicFormComponent implements OnInit, OnDestroy {
  @Input()
  components;
  @ViewChild(FormHostDirective, { static: true }) formHost: FormHostDirective;
  config: ICallbackHandlers;

  active = false;
  progressActive = true;
  authenticated = false;
  amsub: Subscription;
  loginsub: Subscription;
  paramSub: Subscription;
  dialogRefSub: Subscription;
  authtree: string = null;
  componentRef: any;

  constructor(
    public dialog: MatDialog,
    private openAMHTTPService: OpenAMHTTPService,
    private openAMEventService: OpenAMEventService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private route: ActivatedRoute,
    private session: SessionStorageService,
    private framauthui: FRAMAuthUI,
    private store: Store<any>,
    @Inject(WINDOW) private _window: any) {
  }

  ngOnInit() {
    this.amsub = this.openAMEventService.cartData
      .subscribe(
        (event: any) => {
          if (event instanceof Error) {
            this.progressActive = false;
            this.active = true;
            this.session.clear();
            this.initComponent(event);
          }
          if (event instanceof Unauthorized) {
            this.session.clear();
            this.initComponent(event);
            this.progressActive = false;
          }
          if (event instanceof LoginAttempt) {
            this.config = event.config;
            this.progressActive = true;
            this.active = false;

            this.openAMHTTPService.submit(this.config, location.search).subscribe((c: ICallbackHandlers) => {
              this.config = c;
              this.loadComponent();
            }, error => {
              this.session.clear()
            });
          }
          if (event instanceof Authenticated) {
            this.authenticated = true;
            this.progressActive = false;
          }
        });
    this.paramSub = this.route.queryParams.subscribe(queryParams => {
      this.initComponent();
    });
  }

  ngOnDestroy() {
    if (this.amsub) {
      this.amsub.unsubscribe();
    }
    if (this.loginsub) {
      this.loginsub.unsubscribe();
    }
    if (this.paramSub) {
      this.paramSub.unsubscribe();
    }
    if (this.dialogRefSub) {
      this.dialogRefSub.unsubscribe();
    }
  }

  getRealm(): string {
    let realm = this.framauthui.settings.fram.urls[this._window.location.hostname].realm;
    realm = realm !== undefined ? 'realms/' + realm + '/' : "";
    return realm;
  }

  getService(): string {
    let service = this.framauthui.settings.fram.urls[this._window.location.hostname].service;
    return service ? service : "";
  }

  initComponent(framEvent?: Error | Unauthorized) {
    this.authtree = this.getService();
    if (this.authtree) {
      this.authtree = `service=${this.authtree}&authIndexType=service&authIndexValue=${this.authtree}`;
    }

    if (this.session.get('authid')) {
      const obj: { [k: string]: any } = {};
      obj.authId = this.session.get('authid');
      obj.realm = this.session.get('realm');
      const config = { ...obj, ...this.route.snapshot.queryParams };
      this.session.remove('realm');
      this.session.remove('authid');
      const e = new LoginAttempt(config);
      this.openAMEventService.cartData.emit(e);
    } else {
      this.loginsub = this.openAMHTTPService.login(this.authtree).subscribe(
        (c: ICallbackHandlers) => {
          this.config = c;
          if (!this.authenticated) {
            this.loadComponent(framEvent);
          }
        });
    }
  }

  loadComponent(framEvent?: Error | Unauthorized) {
    if (this.config.authId) {
      if (!this.config.stage) {
        this.config.stage = 'AuthTree';
        this.session.set('realm', this.getRealm());
        this.session.set('authid', this.config.authId);
      }
      if (this.config.stage) {
        const stage = this.config.stage.replace(/\d+$/, '');
        if (!this.components[stage]) {
          const supportedTypes = Object.keys(this.components).join(', ');
          throw new Error(
            `Trying to use an unsupported type (${this.config.stage}).
        Supported types: ${supportedTypes}`
          );
        }
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.components[stage]);
        const viewContainerRef = this.formHost.viewContainerRef;
        viewContainerRef.clear();
        this.componentRef = viewContainerRef.createComponent(componentFactory);
        (<DynamicFormComponent>this.componentRef.instance).config = this.config;
        if (event) {
          (<DynamicFormComponent>this.componentRef.instance).framEvent = framEvent;
        }
        this.progressActive = false;
        this.active = true;
      }
    }
  }
}
