import { Authenticated, FRAMUser } from '../../models/authentication';
import { AuthService } from '../auth/auth.service';
import { FRAMAuthUI } from '../../core/framauthui-core';
import { Injectable, Inject } from '@angular/core';
import { ParsedIdToken } from '.';
import { UrlHelperService } from './urlHelper.service';
import { WINDOW } from 'ngx-window-token';

/**
 * extracted from https://github.com/manfredsteyer/angular-oauth2-oidc/blob/master/projects/lib/src/oauth-service.ts
 */
@Injectable()
export class OAuthService {
    private _storage: Storage;
    private clientId;
    private issuer;

    constructor(
        private urlHelper: UrlHelperService,
        private authService: AuthService,
        private framauthui: FRAMAuthUI,
        @Inject(WINDOW) private _window: any
    ) {
        this.setStorage(sessionStorage);
        this.issuer = this.framauthui.settings.oauth2.issuer;
        this.clientId = this.framauthui.settings.oauth2.clientId;
    }

    getBaseURL(): string {
        return this.framauthui.settings.fram.urls[this._window.location.hostname].baseURL;
    }

    getRealm(): string {
        let realm = this.framauthui.settings.fram.urls[this._window.location.hostname].realm;
        realm = realm !== '' ? realm + '/' : realm;
        return realm;
    }

    initImplicitFlowInternal() {
        this.createLoginURL(this.createNonce())
            .then(function (url) {
                location.href = url;
            })
            .catch(error => {
                console.error('Error in initImplicitFlow');
                console.error(error);
            });
    }

    createLoginURL(csrf: string) {
        return this.createAndSaveNonce().then((nonce: any) => {
            const url =
                this.getBaseURL() + '/oauth2/' + this.getRealm()
                + 'authorize' +
                '?client_id=' + this.framauthui.settings.oauth2.clientId +
                '&response_type=' + encodeURIComponent('token id_token') +
                '&scope=' + encodeURIComponent(this.framauthui.settings.oauth2.scopes) +
                '&redirect_uri=' + encodeURIComponent(this.framauthui.settings.oauth2.redirectURI) +
                '&nonce=' + nonce +
                '&csrf=' + encodeURIComponent(csrf) +
                '&decision=allow';
            return url;
        });
    }

    public tryLogin(): Promise<boolean> {
        let parts: object;
        parts = this.urlHelper.getHashFragmentParams();
        if (parts['error']) {
            return Promise.reject('hello');
        }
        const state = parts['state'];
        const accessToken = parts['access_token'];
        const idToken = parts['id_token'];
        const sessionState = parts['session_state'];
        const grantedScopes = parts['scope'];

        this.storeAccessTokenResponse(
            accessToken,
            null,
            parts['expires_in'] || 360,
            grantedScopes
        );
        return this.processIdToken(idToken, accessToken)
            .then(result => {
                this.storeIdToken(result);
                this.authService.loggedin(this.getFRAMUser(result));
                return Promise.resolve(true);
            })
            .catch(reason => {
                console.error('Error validating tokens');
                console.error(reason);
                return Promise.reject(reason);
            });
    }


    public processIdToken(
        idToken: string,
        accessToken: string
    ): Promise<ParsedIdToken> {
        const tokenParts = idToken.split('.');
        const headerBase64 = this.padBase64(tokenParts[0]);
        const headerJson = atob(headerBase64);
        const header = JSON.parse(headerJson);
        const claimsBase64 = this.padBase64(tokenParts[1]);
        const claimsJson = atob(claimsBase64);
        const claims = JSON.parse(claimsJson);
        const savedNonce = this._storage.getItem('nonce');

        if (Array.isArray(claims.aud)) {
            if (claims.aud.every(v => v !== this.clientId)) {
                const err = 'Wrong audience: ' + claims.aud.join(',');
                console.warn(err);
                return Promise.reject(err);
            }
        } else {
            if (claims.aud !== this.clientId) {
                const err = 'Wrong audience: ' + claims.aud;
                console.warn(err);
                return Promise.reject(err);
            }
        }

        if (!claims.sub) {
            const err = 'No sub claim in id_token';
            console.warn(err);
            return Promise.reject(err);
        }

        if (!claims.iat) {
            const err = 'No iat claim in id_token';
            console.warn(err);
            return Promise.reject(err);
        }

        if (claims.iss.toLowerCase() !== this.issuer.toLowerCase()) {
            const err = 'Wrong issuer: ' + claims.iss;
            console.warn(err);
            return Promise.reject(err);
        }

        if (claims.nonce !== savedNonce) {
            const err = 'Wrong nonce: ' + claims.nonce;
            console.warn(err);
            return Promise.reject(err);
        }

        const now = Date.now();
        const issuedAtMSec = claims.iat * 1000;
        const expiresAtMSec = claims.exp * 1000;
        const tenMinutesInMsec = 1000 * 60 * 10;

        if (
            issuedAtMSec - tenMinutesInMsec >= now ||
            expiresAtMSec + tenMinutesInMsec <= now
        ) {
            const err = 'Token has been expired';
            console.error(err);
            console.error({
                now: now,
                issuedAtMSec: issuedAtMSec,
                expiresAtMSec: expiresAtMSec
            });
            return Promise.reject(err);
        }

        const result: ParsedIdToken = {
            idToken: idToken,
            idTokenClaims: claims,
            idTokenClaimsJson: claimsJson,
            idTokenHeader: header,
            idTokenHeaderJson: headerJson,
            idTokenExpiresAt: expiresAtMSec
        };
        return Promise.resolve(result);
    }

    private padBase64(base64data): string {
        while (base64data.length % 4 !== 0) {
            base64data += '=';
        }
        return base64data;
    }

    public setStorage(storage: Storage): void {
        this._storage = storage;
    }

    private validateNonceForAccessToken(
        nonceInState: string
    ): boolean {
        const savedNonce = this._storage.getItem('nonce');
        if (savedNonce !== nonceInState) {
            const err = 'validating access_token failed. wrong state/nonce.';
            console.error(err, savedNonce, nonceInState);
            return false;
        }
        return true;
    }

    public createAndSaveNonce(): Promise<string> {
        const that = this;
        const nonce = this.createNonce();
        return new Promise((resolve, reject) => {
            that._storage.setItem('nonce', nonce);
            resolve(nonce);
        });
    }

    protected createNonce(): string {
        let text = '';
        const possible =
            'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (let i = 0; i < 40; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }

    private storeAccessTokenResponse(
        accessToken: string,
        refreshToken: string,
        expiresIn: number,
        grantedScopes: String
    ): void {
        this._storage.setItem('access_token', accessToken);
        if (grantedScopes) {
            this._storage.setItem('granted_scopes', JSON.stringify(grantedScopes.split('+')));
        }
        this._storage.setItem('access_token_stored_at', '' + Date.now());
        if (expiresIn) {
            const expiresInMilliSeconds = expiresIn * 1000;
            const now = new Date();
            const expiresAt = now.getTime() + expiresInMilliSeconds;
            this._storage.setItem('expires_at', '' + expiresAt);
        }

        if (refreshToken) {
            this._storage.setItem('refresh_token', refreshToken);
        }
    }

    protected storeIdToken(idToken: ParsedIdToken) {
        this._storage.setItem('id_token', idToken.idToken);
        this._storage.setItem('id_token_claims_obj', idToken.idTokenClaimsJson);
        this._storage.setItem('id_token_expires_at', '' + idToken.idTokenExpiresAt);
        this._storage.setItem('id_token_stored_at', '' + Date.now());
    }

    protected getFRAMUser(idToken: ParsedIdToken): FRAMUser {
        const user = <FRAMUser>JSON.parse(idToken.idTokenClaimsJson);
        return user;
    }
}
