import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Authenticated, FRAMUser } from '../../models/authentication';
import { selectAccessToken, selectIsAuthenticated } from '../../reducers';
import { authAccessToken, authLogin, authLogout } from '../../reducers/auth.actions';
import { OpenAMEventService } from '../event.service';
import { OpenAMHTTPService } from '../http.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private isAuthenticated = false;
  private accessToken = false;
  private httpService: OpenAMHTTPService;
  constructor(
    private store: Store<any>,
    private injector: Injector,
    private router: Router,
    private route: ActivatedRoute,
    private openAMEventService: OpenAMEventService
  ) {
    this.store
      .select(selectAccessToken)
      .subscribe(auth => {
        this.accessToken = auth;
      }
      );
    this.store
      .select(selectIsAuthenticated)
      .subscribe(auth => {
        this.isAuthenticated = auth;
      }
      );
    this.openAMEventService.cartData.subscribe(
      (data: any) => {
        if (data instanceof Authenticated) {
          this.login(data);
        }
      });
  }

  get isLoggedIn(): boolean {
    return this.isAuthenticated && this.accessToken;
  }

  login(event: Authenticated): void {
    this.store.dispatch(authLogin({ authenticated: event }));
  }

  loggedin(user: FRAMUser): void {
    this.store.dispatch(authAccessToken({ user }));
  }

  logout(): void {
    this.httpService = this.injector.get(OpenAMHTTPService);
    this.httpService.logout<string>().subscribe(
      action => {
        this.store.dispatch(authLogout());
      }
    );
  }

  forceRunAuthGuard(): void {
    if (this.route.root.children.length) {
      // gets current route
      const currentRoute = this.route.root.children['0'];
      // gets first guard class
      const AuthGuard = currentRoute.snapshot.routeConfig.canActivate['0'];
      // injects guard
      const authGuard = this.injector.get(AuthGuard);
      // makes custom RouterStateSnapshot object
      const routerStateSnapshot: RouterStateSnapshot = Object.assign({}, currentRoute.snapshot, { url: this.router.url });
      // runs canActivate
      authGuard.canActivate(currentRoute.snapshot, routerStateSnapshot);
    }
  }
}
