import { AuthTokenTypes } from '../../reducers/auth.reducer';

export interface IAuthenticated {
  realm: string;
  successUrl: string;
  tokenId: string;
}

export class Authenticated {
  authenticated: IAuthenticated;
  constructor(authenticated: IAuthenticated) {
    this.authenticated = authenticated;
  }

  getTokenId(): string {
    return this.authenticated.tokenId;
  }

  getTokenType(): any {
    return AuthTokenTypes.AMTOKEN;
  }
}
