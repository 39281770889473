import { createAction, props } from '@ngrx/store';
import { FRAMChangePassword } from '../models';

export const passwordValid = createAction('[Password] Valid');
export const passwordInvalid = createAction(
    '[Password] Invalid',
    props<{ payload: any }>());
export const passwordChange = createAction(
    '[Password] Change',
    props<{ changePasswordRequest: FRAMChangePassword }>()
);
