<section fxLayout="row wrap" fxLayoutAlign="center center">
    <mat-card appearance="outlined" fxFlex="500px" fxFlex.xs="100%">
        <form [formGroup]="registerForm" autocomplete="off" novalidate (ngSubmit)="createOwner(registerForm.value)"
            fxLayout="column wrap" fxLayoutAlign="center center" fxLayoutGap="10px">
            <mat-card-content>
                <mat-form-field>
                    <mat-label>First Name</mat-label>
                    <input matInput type="text" placeholder="First Name *" formControlName="givenName" id="givenName">
                    <mat-error *ngIf="hasError('givenName', 'required')">First Name is required</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Last Name</mat-label>
                    <input matInput type="text" placeholder="Last Name *" formControlName="sn" id="sn">
                    <mat-error *ngIf="hasError('sn', 'required')">Last Name is required</mat-error>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Email</mat-label>
                    <input matInput type="text" placeholder="Email *" formControlName="mail" id="mail">
                    <mat-error *ngIf="hasError('mail', 'required')">Email is required</mat-error>
                    <mat-error *ngIf="hasError('mail', 'email')">Email must be a valid email address</mat-error>
                </mat-form-field>
            </mat-card-content>
            <mat-card-actions class="center">
                <button mat-raised-button color="primary" [disabled]="!registerForm.valid">Update</button>
            </mat-card-actions>
        </form>
    </mat-card>
</section>