import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SessionStorageService } from 'angular-web-storage';
import { AuthTreeFormComponent, DataStoreFormComponent } from '../../../amextensions';

@Component({
  selector: 'app-auth-login-root',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class AuthLoginComponent implements OnInit {

  components = {
    DataStore: DataStoreFormComponent,
    AuthTree: AuthTreeFormComponent
  };

  constructor(private route: ActivatedRoute, private session: SessionStorageService) {
  }

  ngOnInit(): void {
    this.session.set('auth_goto', this.route.snapshot.queryParamMap.get('goto'));
    this.session.set('returnUrl', this.route.snapshot.queryParamMap.get('returnUrl'));
  }
}
