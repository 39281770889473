import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { FRAMService } from '../../services/fram.service';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import {
  passwordChange,
  passwordInvalid,
  passwordValid,
  SessionState
} from '@darkedges/framauthui';
import { State } from '@ngrx/store';

@Injectable()
export class PasswordEffects {
  constructor(
    private actions$: Actions<Action>, private state: State<SessionState>, private framService: FRAMService
  ) {
  }

  passwordChange$ = createEffect(
    () => this.actions$.pipe(
      ofType(passwordChange),
      exhaustMap(action => {
        const username = this.state.getValue().framauthuiFeature.session.sessionInfo.username;
        const changePasswordRequest = Object.assign({}, action.changePasswordRequest);
        changePasswordRequest.username = username;
        return this.framService.changePassword(username, changePasswordRequest).pipe(
          map(() => passwordValid()),
          catchError(error => of(passwordInvalid({ payload: error }))));
      }
      )
    )
  );
}
