import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { FRAMService } from '../services/fram.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { profileInvalid } from '@darkedges/framauthui';
import { Store } from '@ngrx/store';

@Injectable()
export class LogoutGuard  {

  constructor(private router: Router, private store: Store<any>, private framService: FRAMService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.store.dispatch(profileInvalid({ payload: { error: null, profile: null } }));
    return this.framService.logout().pipe(
      map(res => {
        return res;
      }));
  }
}
