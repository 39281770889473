import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FRAMAlertService } from '../../services';

@Component({
    selector: 'fram-alert',
    templateUrl: 'fram-alert.component.html',
    styleUrls: ['fram-alert.component.scss']
})
export class FRAMAlertComponent implements OnInit, OnDestroy {
    private subscription: Subscription;
    message: any;

    constructor(private alertService: FRAMAlertService) { }

    ngOnInit() {
        this.subscription = this.alertService.getMessage().subscribe(message => {
            this.message = message;
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}