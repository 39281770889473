<fieldset class="fieldset">
    <div *ngIf="message" fxLayout="columns" fxLayoutAlign="start center"
        [ngClass]="{ 'alert': message, 'alert-success': message.type === 'success', 'alert-danger': message.type === 'error' }">
        <div fxFlex="20%">
            <mat-icon aria-hidden="false" aria-label="Example home icon" [fontIcon]="message.icon"></mat-icon>
        </div>
        <div>
            {{message.text}}
        </div>
    </div>
</fieldset>