<form #dynamicForm [formGroup]="form" (submit)="handleSubmit($event)">
    <mat-card appearance="outlined">
        <mat-card-header class="page-header" [ngClass]="{'is-done': active}">
            <mat-card-title class=" text-center"><img src="https://www.darkedges.com/images/connectid_logo.jpg" />
                <br />{{ config.header
                }}
            </mat-card-title>
        </mat-card-header>
        <mat-card-content [ngClass]="{'is-done': active}">
            <fram-alert></fram-alert>
            <ng-container *ngFor="let field of config.callbacks;" dynamicField [config]="field" [group]="form">
            </ng-container>
        </mat-card-content>
        <mat-card-actions [ngClass]="{'is-done': active}" align="middle">
            <ng-container dynamicField [config]="loginField" [group]="form">
            </ng-container>
            <div *ngIf="register">
                New here? <a routerLink="/register">Create an Account</a>
            </div>
        </mat-card-actions>
    </mat-card>
</form>